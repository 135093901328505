import { observable, action, computed } from "mobx";
import { keys, isEmpty } from "lodash";

import Tag from "../models/Tag";

class Tags {
  @observable rawTagsByLot = [];
  @observable rawTagsByRoll = [];
  @observable rawTags = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get tagsByLot() {
    return this.rawTagsByLot;
  }

  @action.bound updateLotTag(newTag) {
    this.rawTagsByLot = this.rawTagsByLot.map(tag => tag.id === newTag.id ? newTag : tag);
  }

  @computed
  get tagsByRoll() {
    return this.rawTagsByRoll;
  }

  @computed
  get tags() {
    return this.rawTags.map(t => ({ ...t, key: t.id }));
  }

  @action.bound reset(tagsType) {
    this.rootStore.abortRequest();
    this.rootStore.resetValidationErrors();
    const typeKey = keys(tagsType)[0];

    switch (typeKey) {
      case "tagsByLot": {
        return (this.rawTagsByLot = []);
      }
      case "tagsByRoll": {
        return (this.rawTagsByRoll = []);
      }
      default:
        return (this.rawTags = []);
    }
  }

  getQueryParams(idsEntity, idKey, paginationConfig, filterOptions = {}) {
    const paginationParams = paginationConfig && {
      limit: paginationConfig.limit,
      offset: paginationConfig.offset
    };

    return idsEntity && idsEntity[idKey]
      ? { ...paginationParams, ...filterOptions, [idKey]: idsEntity[idKey] }
      : { ...paginationParams, ...filterOptions };
  }

  @action.bound storeTags(idKey, data, isLoadMore) {
    switch (idKey) {
      case "lotId": {
        if (isLoadMore) {
          return this.rawTagsByLot = [...this.rawTagsByLot, ...data]
        }
        return (this.rawTagsByLot = data);
      }
      case "rollId": {
        if (isLoadMore) {
          return this.rawTagsByRoll = [...this.rawTagsByRoll, ...data]
        }
        return (this.rawTagsByRoll = data);
      }
      default:
        if (isLoadMore) {
          return this.rawTags = [...this.rawTags, ...data]
        }
        return (this.rawTags = data);
    }
  }

  @action.bound onNextPage = idsEntity => config =>
    this.findAll(idsEntity, config);

  @action.bound async searchTags(
    idsEntity,
    { params: searchParams, ...restParams }
  ) {
    const idKey = keys(idsEntity)[0];
    const { method, url } = this.rootStore.urls.tags.getAll;
    const params = this.getQueryParams(idsEntity, idKey, restParams);

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: { ...searchParams, ...params }
    });

    if (response) return { data: response.data.rows };

    return response;
  }

  //todo: refactoring needed
  @action.bound async findAll(idsEntity, paginationOptions, filterOptions) {
    const { method, url } = this.rootStore.urls.tags.getAll;
    const hasRequestParams =
        (idsEntity && !isEmpty(idsEntity)) ||
        (filterOptions && !isEmpty(filterOptions)) ||
        (paginationOptions && !isEmpty(paginationOptions));

    let params;
    let idKey = "";

    if (hasRequestParams) {
      idKey = idsEntity ? keys(idsEntity)[0] : "";
      params = {
        ...(paginationOptions ? paginationOptions.params : {}),
        ...this.getQueryParams(idsEntity, idKey, paginationOptions, filterOptions)
      };
    }

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params
    });

    if (response) {
      const { rows, count: itemsAmount } = response.data;
      const data = rows.map(t => new Tag(t, this.rootStore));

      if (paginationOptions) {
        paginationOptions.setTotal(itemsAmount);
      }

      this.storeTags(idKey, data, paginationOptions?.isScrollPagination);

      return { data: rows };
    }

    return response;
  }

  @action.bound async findById({ id }) {
    const { method, url } = this.rootStore.urls.tags.getById;
    const { response: { data } } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`
    });
    return data;
  };


  @action.bound async save(tag) {
    const { method, url } = this.rootStore.urls.tags.update

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: [{ ...tag.updateData, id: tag.id }]
    });

    return response;
  };
}

export default Tags;
