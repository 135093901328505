import React, {useEffect, useRef} from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import {Header, Inner} from "../../components/Content";
import Table from "../../components/Table";
import {tagListTableColumns} from "./settings";
import {useTablePaginator} from "../../components/hooks";
import debounce from "lodash/debounce";

const Search = ({ tagsStore, authStore }) => {
  // const [search, setSearch] = useState('')
  const {
    tags,
    findAll,
    reset,
  } = tagsStore;
  const {
    setSearch,
    setSearchHistory,
    search,
    searchHistory
  } = authStore

  const [pagination, paginationReset] = useTablePaginator({
    pageSize: 10,
    limit: 10,
    onPageChange: (paginationConfig) => {
      findAll(null, paginationConfig, {search});
    }
  });

  const delayedQuery = useRef(
    debounce((search) => {
      paginationReset();

      if (search) {
        setSearchHistory(search)
        findAll(null, pagination, {search});
      } else {
        reset();
      }
    }, 500)
  ).current;

  useEffect(() => {
    if (search) {
      findAll(null, pagination, {search});
    }

    return () => {
      reset();
      paginationReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findAll, reset]);

  const onSearch = ({ value }) => {
    setSearch(value)
    delayedQuery(value);
  };

  return (
    <>
      <Header
        title="Search"
        searchPlaceholder="Search by tag id or manufacturer id"
        withHistorySearch
        searchHistory={searchHistory}
        searchDisabled={false}
        search={search}
        onSearch={onSearch}
        withFilter={false}
      />
      <Inner>
        <Table
          columns={tagListTableColumns}
          paginationConfig={pagination}
          data={tags}
        />
      </Inner>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore,
    authStore: rootStore.authStore,
    tagsStore: rootStore.tagsStore
  })),
  observer
)(Search);
